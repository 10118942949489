import { initializeBurgerMenu } from './js/burgerLogic';
import './css/style.scss';

document.addEventListener('DOMContentLoaded', () => {
  initializeBurgerMenu();

  const links = document.querySelectorAll('.navLink');

  const url = window.location.pathname;

  links.forEach(link => {
    if (link.getAttribute('href') === url) {
      setTimeout(() => {
        link.classList.add('active');
      }, 50);
    }
  });
});

document.querySelectorAll('.accordion').forEach(accordion => {
  accordion.addEventListener('click', function () {
    const panel = this.nextElementSibling;
    panel.classList.toggle('show'); // Добавляем/удаляем класс "show"
  });
});
